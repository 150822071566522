import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gallery4.css'

const Gallery4 = (props) => {
  return (
    <div className="gallery4-gallery3 thq-section-padding">
      <div className="gallery4-max-width thq-section-max-width">
        <div className="gallery4-section-title">
          <h2 className="gallery4-text1 thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="gallery4-text4">Gallery</span>
              </Fragment>
            )}
          </h2>
          <span className="gallery4-text2 thq-body-large">
            {props.content1 ?? (
              <Fragment>
                <span className="gallery4-text3">
                  Browse through our gallery to see heartwarming images of the
                  animals we help and the impact of our work.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="gallery4-container1">
          <div className="gallery4-content">
            <div className="gallery4-container2">
              <img
                alt={props.image1Alt}
                src={props.image1Src}
                className="gallery4-image1 thq-img-ratio-16-9"
              />
            </div>
            <div className="gallery4-container3">
              <img
                alt={props.image2Alt}
                src={props.image2Src}
                className="gallery4-image2 thq-img-ratio-1-1"
              />
              <img
                alt={props.image3Alt}
                src={props.image3Src}
                className="gallery4-image3 thq-img-ratio-1-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery4.defaultProps = {
  image2Alt: 'Kitten receiving medical care',
  image3Alt: 'Rescued bunny enjoying a safe environment',
  image2Src:
    'https://images.unsplash.com/photo-1581090465237-2215893ba918?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTIzMjU3OHw&ixlib=rb-4.0.3&q=80&w=1080',
  content1: undefined,
  heading1: undefined,
  image1Alt: 'Cute dog playing in the park',
  image1Src:
    'https://images.unsplash.com/photo-1535223289827-42f1e9919769?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTIzMjU3OXw&ixlib=rb-4.0.3&q=80&w=1080',
  image3Src:
    'https://images.unsplash.com/photo-1500259571355-332da5cb07aa?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTIzMjU3N3w&ixlib=rb-4.0.3&q=80&w=1080',
}

Gallery4.propTypes = {
  image2Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image2Src: PropTypes.string,
  content1: PropTypes.element,
  heading1: PropTypes.element,
  image1Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image3Src: PropTypes.string,
}

export default Gallery4
