import React from 'react'

import PropTypes from 'prop-types'

import './branding.css'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const Branding = (props) => {
  return (
    <Link to='/'>
    <div className={`branding-branding ${props.rootClassName} `}>
      <img alt={'https://rimmon.s3.us-east-1.amazonaws.com/ephratawi+(11).png'} src={props.logoSrc} className="branding-logo" />
    </div>
    </Link>
  )
}

Branding.defaultProps = {
  logoAlt: 'image',
  rootClassName: '',
  companyName: 'Dawit Israel',
  logoSrc: 'https://rimmon.s3.us-east-1.amazonaws.com/ephratawi+(11).png',
}

Branding.propTypes = {
  logoAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  companyName: PropTypes.string,
  logoSrc: PropTypes.string,
}

export default Branding
