import React from 'react'

import PropTypes from 'prop-types'

import './banner.css'

const Banner = (props) => {
  return (
    <div id="banner" data-persistence="true" className="banner-banner">
      <div className="banner-captions">
        <div className="banner-caption1">
          <span className="banner-text1">I'm open to discussing any job opportunities.</span>
        
        </div>
        <a href={props.redirectMobile} className="banner-link2">
          <div className="banner-caption2">
            <span className="banner-text2">I'm open to discussing any job opportunities.</span>
          </div>
        </a>
      </div>
     
    </div>
  )
}


export default Banner
