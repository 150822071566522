import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer31.css'
import { Link } from 'react-router-dom'

const Footer31 = (props) => {
  return (
    <footer
      className={`footer31-footer4 thq-section-padding ${props.rootClassName} `}
    >
      <div className="footer31-max-width thq-section-max-width">
        <div className="footer31-content">
          <div className="footer31-logo">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer31-image1"
            />
          </div>
          <div className="footer31-links">
            <a
              href="/"
              
              rel="noreferrer noopener"
              className="footer31-link1 thq-body-small"
            >
              {props.link1 ?? (
                <Fragment>
                  <span className="footer31-text1">Home</span>
                </Fragment>
              )}
            </a>
            <a
              href="#aboutMe"
              
              rel="noreferrer noopener"
              className="footer31-link2 thq-body-small"
            >
                  <span className="footer31-text4">About Me</span>
            </a>
            <a
              
              rel="noreferrer noopener"
              className="footer31-link3 thq-body-small"
            >
                <Link to='/contact'>
                  <span className="footer31-text8">Contact</span>
                </Link>
            </a>
            <a
              href="#techStack"
              
              rel="noreferrer noopener"
              className="footer31-link4 thq-body-small"
            >
                <Fragment>
                  <span className="footer31-text7">Skills</span>
                </Fragment>
            </a>
           
          </div>
          <div className="footer31-social-links">
       
            <a href='https://github.com/Dawasonnyy' target='_blank'>

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github footer31-icon10 thq-icon-small" viewBox="0 0 16 16">
  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
</svg>
</a>

            <a href='https://www.instagram.com/dawa_sonny?igsh=MWJ4aGhxaHRudHpjcQ==' target='_blank'>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="footer31-icon12 thq-icon-small"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            </a>
            <a href='https://www.linkedin.com/in/dawit-israel-8b7aaa244?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target='_blank'>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="footer31-icon16 thq-icon-small"
            >
              <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
            </svg>
            </a>
        
            <a href='https://t.me/dawa_sonny' target='_blank'>
          
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-telegram footer31-icon18 thq-icon-small" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
</a>

          </div>
        </div>
        <div className="footer31-credits">
          <div className="thq-divider-horizontal"></div>
          <a   className="text-white" style={{fontSize:'12px'}}>contact@dawitisrael.top</a>
          <a   className="text-white" style={{fontSize:'12px'}}>0923580987</a>

       
        </div>
      </div>
    </footer>
  )
}

Footer31.defaultProps = {
  link1: undefined,
  link5: undefined,
  logoAlt: 'Helpa Logo',
  termsLink: undefined,
  link2: undefined,
  privacyLink: undefined,
  cookiesLink: undefined,
  link4: undefined,
  link3: undefined,
  logoSrc: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  rootClassName: '',
}

Footer31.propTypes = {
  link1: PropTypes.element,
  link5: PropTypes.element,
  logoAlt: PropTypes.string,
  termsLink: PropTypes.element,
  link2: PropTypes.element,
  privacyLink: PropTypes.element,
  cookiesLink: PropTypes.element,
  link4: PropTypes.element,
  link3: PropTypes.element,
  logoSrc: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Footer31
