import React, { Fragment, useState } from 'react'

import PropTypes, { func } from 'prop-types'

import './contact-form5.css'
import { doc, setDoc } from 'firebase/firestore'
import { fireStore } from '../firebase'
import { v4 } from 'uuid'
import { Link } from 'react-router-dom/cjs/react-router-dom'


const ContactForm5 = (props) => {
  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [message,setMessage] = useState()
  const [phoneNumber,setphoneNumber] = useState()
  const [loading,setloading] = useState(false)
  const [success,setsuccess] = useState(false)



  function sendMessage(e){
    e.preventDefault()
  setTimeout(() => {
    setloading(true)
    setTimeout(() => {
      setDoc(doc(fireStore,'Messages',v4()),{
        fullName:name,
        email:email,
        message:message,
        phoneNumber:phoneNumber
      }).then(
        setloading(false),
        setsuccess(true)
      )
    }, 1000);
  }, 1000);
  }


  return (
   loading === true ?
   <div style={{width:'80%',margin:'auto'}}>
   <div className=" thq-section-padding shadow-md rounded-md border" style={{backgroundColor:'white',marginTop:'50px',marginBottom:'50px',padding:'20px'}}>
         
      <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>
   </div>
   </div>
   :
   success === true ?
   <div className='successCourseProgram flex-col fixed top-0 left-0 w-full h-full' style={{zIndex:1000}} > 
   <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
   </svg>
   <h1 className='header' style={{fontSize:'40px',color:'black'}}>The message you sent was successfully delivered</h1>
   <p className='par'>I will get in touch with you as soon as possible to confirm your request </p>
   
   <Link to='/'>
   <button
                      type='button'
                     
                      style={{fontSize:'12px',background:'black',height:'55px'}}
                      className='flex items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                    >
                      Done
                    </button></Link>
   </div>
   :
   <div style={{width:'80%',margin:'auto'}}>
   <div className=" thq-section-padding shadow-md rounded-md border" style={{backgroundColor:'white',marginTop:'50px',marginBottom:'50px',padding:'20px'}}>
     <div className="thq-section-max-width thq-flex-column" >
       <div className="contact-form5-section-title thq-flex-column" >
         <span className="thq-body-small">
              <Fragment>
              <span 
                     style={{fontSize:'30px',marginTop:'20px',marginBottom:'20px',textAlign:'center',display:'flex',justifyContent:'center'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"
                     >Get in touch with me today!</span>
            </Fragment>
         </span>
         <div className="contact-form5-content">

           <span className="thq-body-small contact-form5-text12">
              
           </span>
         </div>
       </div>
       

<form onSubmit={sendMessage} class="max-w-md mx-auto" style={{marginTop:'20px',width:'90%'}}>
   <div class="relative z-0 w-full  group">
       <input required onChange={e=>{setName(e.target.value)}} type="text" name="floating_first_name" id="floating_first_name" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" "  />
       <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Full name</label>
   </div>
 
 <div class="relative z-0 w-full mb-5 group">
     <input required onChange={e=>{setEmail(e.target.value)}} type="email" name="floating_email" id="floating_email" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" "  />
     <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
 </div>



   <div class="relative z-0 w-full mb-5 group">
       <input required onChange={e=>{setphoneNumber(e.target.value)}} type="text"  name="floating_phone" id="floating_phone" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " />
       <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number </label>
   </div>
   <div class="relative z-0 w-full  group">
       <textarea onChange={e=>{setMessage(e.target.value)}} type="text" name="floating_first_name" id="floating_first_name" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
       <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Message</label>
   </div>
  
             <button type="submit" className="button-secondary button bg-transparent">Send Message</button>
</form>

     </div>
   </div>
   </div>

  )
}

ContactForm5.defaultProps = {
  action: undefined,
  content2: undefined,
  heading1: undefined,
  content1: undefined,
}

ContactForm5.propTypes = {
  action: PropTypes.element,
  content2: PropTypes.element,
  heading1: PropTypes.element,
  content1: PropTypes.element,
}

export default ContactForm5
