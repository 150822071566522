import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import Number from './number'
import './steps.css'

const Steps = (props) => {
  return (
    <div className="steps-steps-list">
      <div className="steps-list">
        <div className="steps-section10">
          <Number
            text={
              <Fragment>
                <span className="steps-text10">
                  <span>1</span>
                  <br></br>
                </span>
              </Fragment>
            }
          ></Number>
          <span className="steps-caption10">{props.caption4}</span>
        </div>
        <div className="steps-divider1"></div>
        <div className="steps-section11">
          <Number
            text={
              <Fragment>
                <span className="steps-text13">2</span>
              </Fragment>
            }
            value="2"
            rootClassName="numberroot-class-name"
          ></Number>
          <span className="steps-caption11">{props.caption41}</span>
        </div>
        <div className="steps-divider2"></div>
        <div className="steps-section12">
          <Number
            text={
              <Fragment>
                <span className="steps-text14">3</span>
              </Fragment>
            }
            value="3"
            rootClassName="numberroot-class-name1"
          ></Number>
          <span className="steps-caption12">{props.caption411}</span>
        </div>
        <div className="steps-divider3"></div>
        <div className="steps-section13">
          <Number
            text={
              <Fragment>
                <span className="steps-text15">4</span>
              </Fragment>
            }
            value="4"
            rootClassName="numberroot-class-name2"
          ></Number>
          <span className="steps-caption13">{props.caption4111}</span>
        </div>
        <div className="steps-divider4"></div>
        <div className="steps-section14">
          <Number
            text={
              <Fragment>
                <span className="steps-text16">5</span>
              </Fragment>
            }
            value="5"
            rootClassName="numberroot-class-name3"
          ></Number>
          <span className="steps-caption14">{props.caption41111}</span>
        </div>
        <div className="steps-divider5"></div>
        <div className="steps-section15">
          <Number
            text={
              <Fragment>
                <span className="steps-text17">6</span>
              </Fragment>
            }
            value="5"
            rootClassName="numberroot-class-name6"
          ></Number>
          <span className="steps-caption15">{props.caption411116}</span>
        </div>
        <div className="steps-divider6"></div>
        <div className="steps-section16">
          <Number
            text={
              <Fragment>
                <span className="steps-text18">7</span>
              </Fragment>
            }
            value="5"
            rootClassName="numberroot-class-name5"
          ></Number>
          <span className="steps-caption16">{props.caption411115}</span>
        </div>
        <div className="steps-divider7"></div>
        <div className="steps-section17">
          <Number
            text={
              <Fragment>
                <span className="steps-text19">8</span>
              </Fragment>
            }
            value="5"
            rootClassName="numberroot-class-name4"
          ></Number>
          <span className="steps-caption17">{props.caption411111}</span>
        </div>
        <div className="steps-divider8"></div>
        <div className="steps-section18">
          <Number
            text={
              <Fragment>
                <span className="steps-text20">
                  <span className="steps-text21">9</span>
                  <br></br>
                </span>
              </Fragment>
            }
            value="5"
            rootClassName="numberroot-class-name8"
          ></Number>
          <span>
            {props.caption2 ?? (
              <Fragment>
                <span className="steps-caption20">
                  <span>Sed ut perspiciatis unde omnis</span>
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="steps-divider9"></div>
        <div className="steps-section19">
          <Number
            text={
              <Fragment>
                <span className="steps-text23">
                  <span className="steps-text24">10</span>
                  <br></br>
                </span>
              </Fragment>
            }
            value="5"
            rootClassName="numberroot-class-name7"
          ></Number>
          <span>
            {props.caption1 ?? (
              <Fragment>
                <span className="steps-caption21">Git</span>
              </Fragment>
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

Steps.defaultProps = {
  caption411115: 'Sed ut perspiciatis unde omnis',
  caption2: undefined,
  caption41111: 'Sed ut perspiciatis unde omnis',
  caption1: undefined,
  caption411111: 'Sed ut perspiciatis unde omnis',
  caption411116: 'Sed ut perspiciatis unde omnis',
  caption4111: 'Sed ut perspiciatis unde omnis',
  caption41: 'React Js',
  caption4: 'Javascript',
  caption411: 'Firebase',
}

Steps.propTypes = {
  caption411115: PropTypes.string,
  caption2: PropTypes.element,
  caption41111: PropTypes.string,
  caption1: PropTypes.element,
  caption411111: PropTypes.string,
  caption411116: PropTypes.string,
  caption4111: PropTypes.string,
  caption41: PropTypes.string,
  caption4: PropTypes.string,
  caption411: PropTypes.string,
}

export default Steps
