import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './number.css'

const Number = (props) => {
  return (
    <div className={`number-number number ${props.rootClassName} `}>
      <span>
        {props.text ?? (
          <Fragment>
            <span className="number-text2">
              <span>1</span>
            </span>
          </Fragment>
        )}
      </span>
    </div>
  )
}

Number.defaultProps = {
  text: undefined,
  rootClassName: '',
}

Number.propTypes = {
  text: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Number
